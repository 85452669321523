@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
.banner {
  background: linear-gradient(0deg, #ffd408 0%, #fae602 100%);
  padding: 0.5em 0; }
  .banner h2 {
    text-align: center;
    color: #005aa6;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2.2em; }
  .banner.padded {
    padding-bottom: 2em; }
