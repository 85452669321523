@use 'settings';
.body {
  width: 100%; }
  .body.placeholder {
    margin-bottom: 1.5em; }
  .body .body-line {
    width: 100%;
    height: 18px;
    margin-bottom: 0.2em;
    background-image: linear-gradient(to right, #c9c9c9, #c9c9c9, #e6e6e6, #c9c9c9, #c9c9c9);
    background-position: left;
    background-size: 300%;
    animation: placeholder-animation 5s infinite; }
    .body .body-line:last-child {
      width: 60%; }
