.slide {
  position: relative;
  height: 70vh;
  max-height: 56vw;
  background-position: center;
  background-size: cover; }
  .slide.linked {
    cursor: pointer; }
  .slide img {
    width: 100vw;
    height: auto; }
  .slide .slide-title {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 1em 1.5em; }
    .slide .slide-title h2 {
      font-size: 2em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.9); }
      @media (min-width: 992px) {
        .slide .slide-title h2 {
          font-size: 4em; } }
