@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
.footer-form #mc_embed_signup {
  background-color: transparent; }
  .footer-form #mc_embed_signup form #mc_embed_signup_scroll {
    display: flex;
    justify-content: flex-start; }
    .footer-form #mc_embed_signup form #mc_embed_signup_scroll input {
      width: auto;
      flex-grow: 1;
      font-size: 1.2em;
      border-radius: 0.5rem;
      padding: 1.2em 0.75em; }
      .footer-form #mc_embed_signup form #mc_embed_signup_scroll input.email {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: none; }
      .footer-form #mc_embed_signup form #mc_embed_signup_scroll input.button {
        background-color: #fdd307;
        color: #1c1a4c;
        text-transform: uppercase;
        font-family: "Oswald", arial, sans-serif;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        line-height: 0.2;
        font-weight: 500; }
    .footer-form #mc_embed_signup form #mc_embed_signup_scroll .clear {
      width: auto;
      flex-shrink: 1; }
