.slider {
  position: relative;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.8); }
  .slider .arrow-icon {
    font-size: 4em;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.8); }
    .slider .arrow-icon.arrow-left {
      left: 20px; }
    .slider .arrow-icon.arrow-right {
      right: 20px; }
  .slider .rec-slider > div:focus {
    outline: none; }
  .slider .rec-item-wrapper:focus {
    outline: none; }
  .slider .rec-item-wrapper > div {
    width: 100%; }
  .slider .rec-slider-container {
    margin: 0; }
