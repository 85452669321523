.search-page h4 {
  color: #005aa6;
  margin-bottom: 1em; }

.search-page .film-list {
  margin-top: 2rem; }

.search-page .film-filters {
  padding-top: 2rem;
  background-color: #c9c9c9; }
