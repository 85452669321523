@use 'settings';
.filter {
  margin-bottom: 1em; }
  .filter .filter-items {
    max-height: 300px;
    overflow: auto;
    border: 1px solid #aaaaaa;
    padding: 0.5em;
    border-radius: 6px;
    background-color: #e6e6e6; }
  .filter .indicator {
    margin-left: 0.5em; }
  .filter .get-more {
    color: #005aa6;
    cursor: pointer; }
