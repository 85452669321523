.card-teaser {
  margin-bottom: 2em; }
  .card-teaser.no-body .content {
    height: 120px; }
  .card-teaser img {
    width: 100%;
    height: auto; }
  .card-teaser .content {
    background-color: #FFFFFF;
    padding: 1em;
    height: 230px;
    overflow: hidden;
    position: relative;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px; }
  .card-teaser.small h2 {
    font-size: 1.5rem; }
  .card-teaser.small .content {
    height: 180px; }
