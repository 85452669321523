@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
.search-container {
  position: relative;
  z-index: 2; }
  .search-container .search-bar {
    position: absolute;
    bottom: -3.7rem;
    background: #FFFFFF;
    padding: 0.5em;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%); }
    @media (min-width: 768px) {
      .search-container .search-bar {
        width: 60%;
        bottom: -1.7rem; } }
    @media (min-width: 768px) {
      .search-container .search-bar .inputs {
        display: flex; } }
    .search-container .search-bar .inputs .input-item {
      border: none; }
      @media (min-width: 768px) {
        .search-container .search-bar .inputs .input-item {
          font-size: 1.2em; } }
      .search-container .search-bar .inputs .input-item div {
        border: none; }
      .search-container .search-bar .inputs .input-item input:focus {
        border: none; }
    .search-container .search-bar .inputs .bundle-select {
      width: 100%;
      background-color: #fdd307;
      color: #005aa6;
      text-transform: uppercase;
      font-weight: bold; }
      @media (min-width: 768px) {
        .search-container .search-bar .inputs .bundle-select {
          width: auto; } }
    .search-container .search-bar .inputs .item-select {
      flex-grow: 1; }
