.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 3; }
  .modal-overlay .modal-overlay-header {
    text-align: right; }
    .modal-overlay .modal-overlay-header .modal-close-trigger {
      font-size: 2.5em;
      color: #FFFFFF;
      padding: 0.3em 0.5em; }
  .modal-overlay .modal-overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .modal-overlay .modal-overlay-content img {
      width: initial !important; }
    .modal-overlay .modal-overlay-content img, .modal-overlay .modal-overlay-content iframe {
      margin: auto; }
