@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
footer.page-footer {
  padding: 1rem 0 3rem;
  border-top: 10px solid #0074bc; }
  footer.page-footer h4 {
    color: #FFFFFF; }
  footer.page-footer a.button {
    background-color: #FFFFFF;
    color: #1c1a4c;
    font-family: "Oswald", arial, sans-serif;
    padding: 0.1em 1em;
    font-size: 1.4em;
    display: inline-block;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 0.5rem; }
  footer.page-footer .footer-sponsors {
    text-align: center;
    margin: 1em 0; }
  footer.page-footer .footer-menu a {
    color: #FFFFFF;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase; }
    footer.page-footer .footer-menu a:after {
      content: "•";
      margin: 0 0.3em; }
    footer.page-footer .footer-menu a:last-child:after {
      content: "";
      margin: 0; }
  footer.page-footer [class^=col-] + [class^=col-] {
    margin-top: 1.5rem; }
    @media (min-width: 768px) {
      footer.page-footer [class^=col-] + [class^=col-] {
        margin-top: 0; } }
  footer.page-footer .social-icons {
    display: flex; }
    footer.page-footer .social-icons .social-icon {
      color: #FFFFFF;
      font-size: 1.5rem;
      padding-right: 1em; }
  footer.page-footer .footer-copyright {
    font-size: 0.7em;
    text-transform: uppercase; }
    footer.page-footer .footer-copyright div, footer.page-footer .footer-copyright a {
      color: #FFFFFF; }
    footer.page-footer .footer-copyright a {
      margin-left: 1em; }
  @media (min-width: 768px) {
    footer.page-footer .signature {
      text-align: right; } }
