@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
@use 'settings';
.meta {
  margin-bottom: 0.2em; }
  .meta.placeholder .meta-label {
    width: 80px;
    height: 20px;
    margin-bottom: 0.1em;
    background-image: linear-gradient(to right, #c9c9c9, #c9c9c9, #e6e6e6, #c9c9c9, #c9c9c9);
    background-position: left;
    background-size: 300%;
    animation: placeholder-animation 5s infinite; }
  .meta.inline div {
    display: inline-block; }
  .meta .meta-label {
    font-weight: 700;
    margin-right: 0.5em; }
