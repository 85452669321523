@use 'settings';
.hero-image img {
  width: 100%;
  height: auto; }

.hero-image.placeholder {
  width: 100%;
  padding-top: 56%;
  background-image: linear-gradient(to right, #c9c9c9, #c9c9c9, #e6e6e6, #c9c9c9, #c9c9c9);
  background-position: left;
  background-size: 300%;
  animation: placeholder-animation 5s infinite; }
