@use 'settings';
.card.placeholder {
  margin-bottom: 2em;
  border: none;
  background-color: transparent;
  border-radius: 0; }
  .card.placeholder .image {
    height: 223px;
    width: 100%;
    background-image: linear-gradient(to right, #c9c9c9, #c9c9c9, #e6e6e6, #c9c9c9, #c9c9c9);
    background-position: left;
    background-size: 300%;
    animation: placeholder-animation 5s infinite; }
  .card.placeholder .content {
    background-color: #FFFFFF;
    padding: 1em;
    height: 230px;
    overflow: hidden;
    position: relative;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px; }
  .card.placeholder .body {
    height: 1em;
    margin-bottom: 0.3em;
    background-image: linear-gradient(to right, #c9c9c9, #c9c9c9, #e6e6e6, #c9c9c9, #c9c9c9);
    background-position: left;
    background-size: 300%;
    animation: placeholder-animation 5s infinite; }
  .card.placeholder .title {
    height: 2.3rem;
    margin-bottom: .5rem;
    background-image: linear-gradient(to right, #c9c9c9, #c9c9c9, #e6e6e6, #c9c9c9, #c9c9c9);
    background-position: left;
    background-size: 300%;
    animation: placeholder-animation 5s infinite; }
