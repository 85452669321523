@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
.back-to-top {
  position: fixed;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fdd307;
  z-index: 2;
  margin: auto;
  text-align: center;
  font-family: "Oswald", arial, sans-serif;
  font-weight: bold;
  font-size: 1.3em;
  color: #1c1a4c;
  padding: 0.7rem 2rem;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  cursor: pointer; }
