@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
.browse-container {
  position: relative;
  z-index: 2; }
  .browse-container .browse-bar {
    position: absolute;
    bottom: -1.2rem;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%); }
    @media (min-width: 768px) {
      .browse-container .browse-bar {
        width: 300px; } }
    .browse-container .browse-bar select {
      background-color: #005aa6;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: #FFFFFF;
      font-weight: bold;
      border: none;
      width: 100%; }
