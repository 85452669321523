@use 'settings';
.media-teaser {
  position: relative; }
  .media-teaser.placeholder {
    width: 100%;
    padding-top: 56%;
    background-image: linear-gradient(to right, #c9c9c9, #c9c9c9, #e6e6e6, #c9c9c9, #c9c9c9);
    background-position: left;
    background-size: 300%;
    animation: placeholder-animation 5s infinite; }
  .media-teaser img {
    width: 100%;
    height: auto; }
  .media-teaser.media--image .play {
    display: none; }
  .media-teaser .caption {
    position: absolute;
    bottom: 0;
    color: #FFFFFF;
    padding: 0.2em 1em;
    width: 100%;
    background-color: rgba(28, 26, 76, 0.7);
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase; }
  .media-teaser .play {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 4rem;
    color: rgba(255, 255, 255, 0.6); }
