header {
  border-bottom: 10px solid #0074bc;
  font-size: 2rem; }
  header .navbar {
    padding: 0 1rem; }
  header .navbar-dark {
    width: 100%; }
    header .navbar-dark .navbar-toggler {
      border: none; }
    header .navbar-dark a.navbar-brand,
    header .navbar-dark .search-expander {
      padding: 0;
      margin: 0 0.5em; }
    header .navbar-dark a.navbar-brand {
      font-size: 2rem; }
    header .navbar-dark #basic-navbar-nav {
      border-left: 2px solid #0074bc;
      line-height: 2.5; }
    header .navbar-dark .navbar-nav {
      margin: auto; }
      header .navbar-dark .navbar-nav .nav-link {
        color: #FFFFFF;
        text-transform: uppercase;
        padding: 0 0.5em; }
        header .navbar-dark .navbar-nav .nav-link:hover {
          color: #fdd307; }
    header .navbar-dark .search-expander {
      color: #FFFFFF; }
