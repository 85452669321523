.carousel {
  margin: 1em -10px; }
  .carousel.placeholder {
    margin: 1em 0; }
  .carousel .arrow-icon {
    font-size: 3em;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.8); }
    .carousel .arrow-icon.arrow-left {
      left: 20px; }
    .carousel .arrow-icon.arrow-right {
      right: 20px; }
  .carousel .rec-slider > div:focus {
    outline: none; }
  .carousel .rec-item-wrapper:focus {
    outline: none; }
  .carousel .rec-item-wrapper > div {
    width: 100%; }
  .carousel .rec-slider-container {
    margin: 0; }
